*:not(img)::selection {
  background-color: #ff8a13;
  color: #fff;
}

html {
  @media (max-width: $md) {
    font-size: 15px;
  }

  @media (max-width: $sm) {
    font-size: 14px;
  }
}

body {
  font-family: $f-regular;
  color: $c-font;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
  padding-right: 15px;
}

img {
  user-select: none;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: $xs) {
  .container {
    width: 540px;
  }
}

@media only screen and (min-width: $sm) {
  .container {
    width: 720px;
  }
}

@media only screen and (min-width: $md) {
  .container {
    width: 960px;
  }
}

@media only screen and (min-width: $lg) {
  .container {
    width: 1169px;
  }
}

.heading {
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 32px;
  color: #404040;

  @media (max-width: $md) {
    font-size: 42px;
    margin-bottom: 42px;
  }
}

.subheading {
  font-size: 21px;
  font-weight: 300;
  font-family: $f-light;
  letter-spacing: .08px;
  margin: 0;
  line-height: 32px;
}

label {
  margin-bottom: 0;
  user-select: none;
}

h2 {
  font-size: 40px;
  color: #666;
  font-family: $f-regular;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .33pt;
  text-align: center;
  line-height: 1;
  margin-bottom: 20px;
}

.text-wrapper {
  max-width: 770px;
  text-align: center;
  line-height: 27px;
}

.text-bold {
  font-family: $f-medium;
}
