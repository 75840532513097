@import "../../styles/variables/variables";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  display: none;

  &.is-active {
    display: block;
  }
}

.modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.modal-dialog {
  position: relative;
  max-height: calc(100vh - 100px);
  margin: 50px auto;
  max-width: 880px;
  background-color: #fff;
  border: 1px solid #eee;
  overflow-y: auto;
  border-radius: 5px;
  z-index: 210;
}

.modal-dialog__header,
.modal-dialog__body {
  padding: 30px;
}

.modal-dialog__header {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  h2 {
    margin-bottom: 0;
    text-align: left;
    line-height: 1;
  }
}

.modal-dialog__footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: 15px;

  .btn {
    min-width: 120px;
  }
}
