@import "../../styles/variables/variables";

.radio__btn {
  position: relative;
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid #dedede;
  border-radius: 50%;
  margin-right: 10px;

  &::after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.radio {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;

  input:checked {
    ~ .radio__btn::after {
      background-color: $c-accent;
    }
  }
}

.radio__title {
  margin-right: 14px;
  color: #404040;
  font-family: $f-medium;
}

.radio-group {
  display: flex;

  .radio {
    ~ .radio {
      margin-left: 40px;
    }
  }

  @media (max-width: $sm) {
    flex-direction: column;

    .checkbox {
      margin-bottom: 10px;
    }

    .radio__title,
    .radio {
      margin-bottom: 6px;
    }

    .radio ~ .radio {
      margin-left: 0;
    }
  }
}

.radio__label {
  font-size: 15px;
}
