@import "../../styles/variables/variables";

.contacts {
  font-size: 18px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-item__photo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border: 5px solid #ececec;

  @media (max-width: $sm) {
    width: 102px;
    height: 102px;
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.contact-item__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 124px;
  color: #404040;
  margin-left: 22px;

  @media (max-width: $sm) {
    margin-left: 8px;
  }
}

.contact-item__name {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 8px;

  @media (max-width: $sm) {
    font-size: 21px;
  }
}

.contact-item__phone {
  font-size: 21px;
  font-family: $f-medium;
  color: #404040;
  text-decoration: none;
  line-height: 1;
  margin-bottom: 6px;

  @media (max-width: $sm) {
    font-size: 18px;
  }

  &:hover {
    color: #404040;
    text-decoration: none;
  }
}

.contact-item__position {
  font-size: 18px;
  color: #666;
  line-height: 1;
  margin-bottom: 15px;

  @media (max-width: $sm) {
    font-size: 16px;
  }
}

.contact-item__mail {
  font-size: 14px;
  color: #404040;
  line-height: 1;

  &:hover {
    color: #404040;
  }
}

.contacts__items {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;

  @media (max-width: $md) {
    flex-direction: column;
    align-items: center;

    .contact-item ~ .contact-item {
      margin-top: 16px;
    }
  }

  @media (min-width: $md) {
    &::before {
      content: "";
      display: block;
      background-color: #999;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 111px;
      opacity: .3;
    }
  }
}
