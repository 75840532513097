.link {
  font-size: 14px;
  text-decoration: underline;
  color: $c-light;
  font-family: $f-medium;
  letter-spacing: .14px;

  &:hover {
    opacity: .9;
    color: $c-light;
  }

  &--xl {
    font-size: 18px;
  }

  &--primary {
    color: $c-primary;
    text-decoration: none;

    &:hover {
      color: $c-primary;
      text-decoration: none;
    }
  }

  &--accent {
    color: $c-accent;
    text-decoration: none;

    &:hover {
      color: $c-accent;
    }

    &::selection {
      background-color: $c-primary;
      color: #fff;
    }
  }
}

.reset_filters {
  margin-right: 20px;

  &:hover {
    color: $c-accent;
  }
}

.example_link {
  color: $c-accent;
  margin-right: 20px;

  &:hover {
    color: $c-accent;
  }
}
