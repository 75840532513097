.footer {
  height: 50px;
  padding: 15px 0;
  text-align: center;
  font-size: 13px;
  color: #ccc;
  background-color: #f7f7f7;
  border-top: 1px solid #e7e7e7;

  .link {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
    text-decoration: none;
    color: $c-inactive;
  }
}

.footer__years {
  padding-left: 5px;
  padding-right: 5px;
}

.help-block {
  color: #f00;
  font-size: 10px;
  position: absolute;
  top: 42px;
}

.has-error {
  position: relative;
}

.tabs {
  position: relative;
}

.spinner-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  display: none;

  img {
    margin-top: 9%;
    width: 100px;
  }
}

.select__collapse-items {
  max-height: 255px;
  overflow-y: auto;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  .close-btn {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    position: inherit;
    background-color: transparent;
  }

  p {
    margin-bottom: 0;
  }
}

.link_big {
  font-size: 1.3rem;
}
