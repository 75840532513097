@import "../../styles/variables/variables";

.form-group {
  &:not(:last-child) {
    margin-bottom: 35px;
  }
}

.input-group {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 50px;
  }

  @media (max-width: $md) {
    flex-direction: column;
    align-items: flex-start;

    .input__label {
      padding-left: 0;
      width: 40px;
    }

    .input:not(:first-of-type) {
      margin-top: 6px;
    }
  }
}

.input__label {
  font-size: 15px;
  color: #404040;
  text-transform: lowercase;
  padding-right: 10px;
}

.input {
  display: flex;
  align-items: center;

  ~ .input {
    .input__label {
      padding-left: 10px;
    }
  }
}

.input-group__title {
  color: #404040;
  user-select: none;
  margin-right: 4px;
}

.input__field {
  min-width: 90px;
  padding: 0 15px;
  font-size: 14px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 17px;
  box-shadow: none;

  &:focus {
    outline: 0;
    border-color: $c-primary;
  }

  &--xs {
    width: 90px;
  }
}

.input__tag {
  color: $c-inactive;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.has-error {

  input {
    border-color: rgba(255, 0, 0, .5);
    background: rgba(255, 0, 0, .025);
  }
}

.help-block {
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
  padding: 12px;
  min-width: 210px;

  &:empty {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 25%;
    top: -6px;
    transform: rotate(45deg);
    border: 1px solid #dedede;
    border-bottom: 0;
    border-right: 0;
    z-index: 2;
  }
}
