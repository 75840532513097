@import "../../styles/variables/variables";

.btn-separate {
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid currentColor;
}

.btn {
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 15px;
  transition: all .5s ease-in-out;
  letter-spacing: .12px;
  border: 2px solid transparent;
  cursor: pointer;

  &--default {
    background-color: #fff;
    border-color: $c-accent;
    color: $c-accent;

    &:hover {
      color: $c-accent;
      text-decoration: none;
    }
  }

  &--accent {
    background-color: $c-accent;
    border-color: $c-accent;
    color: #fff;

    &:hover {
      color: $c-accent;
      border-color: $c-accent;
      background-color: #fff;
      background-image: none;
      text-decoration: none;
    }
  }
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0;
  box-shadow: none;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  opacity: .9;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }

  span {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $c-accent;
    line-height: 24px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .9;

    //  <3 IE 11
    @media all and (-ms-high-contrast: none) {
      font-size: 12px;
    }

    // <3 Edge too
    @supports (-ms-ime-align: auto) {
      font-size: 12px;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.link-underline {
  text-decoration: underline;
}
