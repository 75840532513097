.box-section {
  padding-bottom: 0;
}

.box {
  background-color: #fff;
  border-radius: 20px;
  padding: 55px 55px 55px 40px;
  color: #404040;
}

.box__title {
  h2 {
    font-size: 39px;
    text-align: left;
    color: #404040;
    line-height: 50px;

    @media (max-width: $md) {
      font-size: 30px;
      line-height: 32px;

      br {
        display: none;
      }
    }
  }
}

.box__body {
  display: flex;

  .btn {
    display: block;
  }

  .box__info {
    max-width: 350px;
    width: 100%;

    &--full {
      max-width: 550px;

      .btn {
        display: inline-block;
      }
    }
  }

  @media (max-width: $md) {
    flex-direction: column-reverse;
  }

  .box__text {
    padding: 0;
    font-size: 21px;
    margin-bottom: 30px;
  }
}

.box__text {
  padding-left: 70px;
  padding-right: 20px;
  color: #666;
  letter-spacing: 0.08px;
  font-weight: 300;
  font-size: 21px;
  text-align: left;

  @media (max-width: $md) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
}

.box__address {
  margin-bottom: 30px;

  span,
  .link {
    font-weight: 500;
    font-size: 18px;
    display: block;
  }
}

.box__description {
  padding-top: 30px;
}

.box__footer {
  padding-top: 30px;
  color: #999;
}
