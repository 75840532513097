// Fonts
@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

$f-light: Roboto-Light;
$f-medium: Roboto-Medium;
$f-regular: Roboto-Regular;
$f-bold: Roboto-Bold;
$f-black: Roboto-Black;

// Colors
$c-font: #666;
$c-primary: #2ca2ed;
$c-accent: #f77f03;
$c-light: #999;
$c-inactive: #ababab;

// Media Queries
$xxs: 320px;
$xs: 576px;
$sm: 767px;
$md: 992px;
$lg: 1200px;
$ga: 1366px;
