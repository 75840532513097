@import "../../styles/variables/variables";

.select {
  position: relative;

  &--fixed {
    min-width: 245px;
    max-width: 245px;
  }
}

.select__collapse {
  display: none;
  position: absolute;
  width: 100%;
  text-align: left;
  border: 1px solid $c-primary;
  border-top: 0;
  transform: translateY(-1px);
  background-color: #fff;
  box-shadow: 0 4px 3px rgba(0, 0, 0, .1);
  z-index: 5;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 333px;
  border-radius: 0 0 17px 17px;
  padding-top: 10px;

  &::-webkit-scrollbar-track {
    display: none;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    // background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    display: none;
    // width: 10px;
    // background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    // background-color: #f90;
    // background-image: linear-gradient(to top right, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  }
}

.select__button {
  position: relative;
  width: 100%;
  box-shadow: none;
  border: 1px solid #dedede;
  padding: 6px 24px 6px 12px;
  font-size: 15px;
  border-radius: 17px;
  background-color: #fff;
  color: #404040;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    outline: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 14px;
    right: 10px;
    border-color: transparent;
    border-top-color: #888;
    border-style: solid;
    border-width: 5px 4px 0;
  }

  &.is-active {
    border-radius: 17px 17px 0 0;
    border-color: $c-primary;

    &::after {
      transform: rotate(.5turn);
    }

    ~ .select__collapse {
      display: block;
    }
  }
}

.select__item {
  display: block;
  padding: 6px 12px;
  color: #333;
  cursor: pointer;

  &.checkbox {
    display: flex;
  }

  .checkbox__label {
    color: #404040;
  }

  &:hover,
  &.is-active {
    text-decoration: none;
    color: #fff;
    background-color: $c-primary;

    .checkbox__label {
      color: #fff;
    }
  }
}

.select__submit {
  text-align: center;
  padding: 15px 20px 20px;

  .btn {
    height: 32px;
    width: 100%;
    padding: 5px 25px;
  }
}
