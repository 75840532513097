@import "../../styles/variables/variables";

.disclaimer {
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 12px;
  color: #ababab;
  text-align: justify;

  &::before {
    content: "";
    display: block;
    width: 70px;
    height: 1px;
    background-color: #dbdbdb;
    transform: translateY(-20px);
  }
}

.disclaimer__title {
  font-size: 12px;
  font-family: $f-bold;
}

.disclaimer__text {
  margin-bottom: 0;
}
