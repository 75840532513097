@import "../../styles/variables/variables";

.resources {
  padding-top: 50px;
  padding-bottom: 50px;

  h2 {
    margin-bottom: 40px;
  }

  @media (max-width: $md) {
    padding-top: 30px;
    padding-bottom: 30px;

    h2 {
      margin-bottom: 20px;
    }
  }
}

.resources__columns {
  display: flex;

  @media (max-width: $md) {
    flex-direction: column;
    text-align: justify;
  }
}

.resources__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: $md) {
    width: 50%;

    &:first-child {
      margin-right: 30px;
    }
  }
}

.resources__item {
  p {
    margin-top: 0;
  }
}
