@import "../../styles/variables/variables";

.checkbox-group__title {
  display: inline-block;
  font-family: $f-medium;
  margin-bottom: 15px;
  color: #404040;
}

.checkbox__box {
  position: relative;
  display: inline-block;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-right: 15px;
  background-color: #fff;

  &::after,
  &::before {
    content: "";
    position: absolute;
    display: none;
    background-color: $c-accent;
  }

  &::after {
    width: 12px;
    height: 2px;
    top: 8px;
    left: 5px;
    transform: rotate(-56deg);
  }

  &::before {
    width: 6px;
    height: 2px;
    top: 10px;
    left: 3px;
    transform: rotate(45deg);
  }
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input:checked ~ .checkbox__box {
    &::after,
    &::before {
      display: block;
    }
  }
}

.checkbox-group__list {
  display: flex;

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.checkbox__label {
  font-size: 15px;
}

.checkbox-column-group {
  margin-right: 30px;

  @media (max-width: $md) {
    margin-right: 14px;
  }

  @media (max-width: $sm) {
    margin-right: 0;
  }

  .checkbox {
    margin-bottom: 10px;
  }
}
