@import "../../styles/variables/variables";

.switch__title {
  font-size: 15px;
  color: #404040;
  margin-right: 15px;
}

.switch__tumbler {
  position: relative;
  width: 48px;
  height: 23px;
  border-radius: 12px;
  border: 1px solid #dedede;

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #bebebe;
    transition: transform linear .2s;
    transform: translateX(0);
  }
}

.switch {
  display: flex;
  align-items: center;
  cursor: pointer;

  input:checked {
    ~ .switch__tumbler::after {
      transform: translateX(108%);
      background-color: #f77f03;
    }
  }
}
