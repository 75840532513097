@import "../../styles/variables/variables";

.tabs {
  margin-bottom: 80px;
}

.tabs__nav {
  display: flex;
  margin-bottom: -1px;
}

.tabs__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: 33px;
  height: 72px;
  text-transform: uppercase;
  font-family: $f-medium;
  border-radius: 20px 20px 0 0;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-bottom-color: rgba(217, 217, 217, .7);
  color: $c-font;
  font-size: 18px;

  @media (max-width: $md) {
    height: 54px;
    font-size: 16px;
  }

  @media (max-width: $sm) {
    padding: 20px;
  }

  &:focus {
    outline: 0;
  }

  &:hover,
  &:active {
    text-decoration: none;
    color: $c-accent;
    background-color: #fff;
    border-color: rgba(217, 217, 217, .7);
    border-bottom-color: #fff;
  }

  &.is-active {
    background-color: #fff;
    color: $c-accent;
    border-color: rgba(217, 217, 217, .7);
    border-bottom-color: #fff;
    cursor: default;
  }

  ~ .tabs__link {
    margin-left: 20px;
  }
}

.tabs__body {
  border: 1px solid rgba(217, 217, 217, .7);
}

.tabs__filters {
  display: flex;
  align-items: center;
  padding: 26px;

  &--between {
    justify-content: space-between;
  }

  @media (max-width: $md) {
    flex-direction: column;
    align-items: flex-start;

    .input-group {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .select {
    flex: 1;

    @media (min-width: $sm) {
      margin-right: 30px;
    }
  }

  .checkbox-group__list .checkbox {
    margin-bottom: 0;
  }

  ~ .tabs__filters {
    padding-top: 0;
  }
}

.advanced-form {
  display: none;
  position: relative;
  padding: 26px;
  border-top: 1px solid rgba(217, 217, 217, .7);

  &.is-active {
    display: block;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    right: 50px;
    top: -8px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    background-color: #fff;
    border: 1px solid rgba(217, 217, 217, .7);
    border-right: 0;
    border-bottom: 0;
  }
}

.tabs__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;

  .btn-submit {
    display: block;
    margin-left: 25px;
    margin-right: 25px;
  }

  @media (max-width: $sm) {
    flex-direction: column;

    .btn-submit {
      margin: 15px 0;
    }
  }
}

.tabs__result {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:empty {
    &::before {
      content: "0 Results";
      font-family: $f-regular;
      font-size: 18px;
      color: #404040;
    }
  }
}

.tabs__filters-checkboxes {
  display: flex;
  align-items: center;

  .checkbox:not(:last-child) {
    margin-right: 30px;
  }

  @media (max-width: $xs) {
    flex-direction: column;
    align-items: flex-start;

    .checkbox:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.second-tab {
  @media (max-width: $md) {
    flex-direction: row;

    .select--fixed {
      margin-bottom: 30px;
    }
  }

  @media (max-width: $sm) {
    flex-direction: column;
    align-items: flex-start;

    .select.ml-30 {
      margin-left: 0;
      margin-top: 30px;
    }
  }

  .input-group {
    @media (max-width: $sm) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px 20px;
}

.search-result__links {
  margin-bottom: 30px;

  .link {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.search-result__count, .search-result__value {
  display: flex;
  flex-direction: column;
}
.search-result__count {
  align-items: flex-end;
}
.search-result__value {
  align-items: flex-start;
}

.search-result__firms {
  /*display: grid;
  grid-template-columns: 1fr 1fr;*/
  font-family: $f-bold;
  font-size: 18px;
  color: #404040;

  .res_count {
    display: flex;
    justify-content: flex-end;
  }
}

.search-result__value {
  margin-right: 5px;
}

.search-result__executives {
  /*display: grid;
  grid-template-columns: 1fr 1fr;*/
  font-size: 14px;
  color: $c-light;

  .res_sub_count {
    display: flex;
    justify-content: flex-end;
  }
}

.tab {
  display: none;

  &.is-active {
    display: block;
  }
}

.tab-title {
  display: flex;
  justify-content: flex-end;
  width: 166px;
  padding-right: 5px;
  margin-right: 15px;
  color: #404040;
  font-family: $f-medium;

  @media (max-width: $md) {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.input-group__title {
  @media (max-width: $md) {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
