/* doiuse-disable */
*, ::after, ::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

[role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* doiuse-enable */
/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible:focus:not(.focus-visible) {
  outline: 0;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

*:not(img)::selection {
  background-color: #ff8a13;
  color: #fff;
}

@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: Roboto-Regular;
  color: #666;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
  padding-right: 15px;
}

img {
  user-select: none;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media only screen and (min-width: 767px) {
  .container {
    width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1169px;
  }
}

.heading {
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 32px;
  color: #404040;
}

@media (max-width: 992px) {
  .heading {
    font-size: 42px;
    margin-bottom: 42px;
  }
}

.subheading {
  font-size: 21px;
  font-weight: 300;
  font-family: Roboto-Light;
  letter-spacing: .08px;
  margin: 0;
  line-height: 32px;
}

label {
  margin-bottom: 0;
  user-select: none;
}

h2 {
  font-size: 40px;
  color: #666;
  font-family: Roboto-Regular;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .33pt;
  text-align: center;
  line-height: 1;
  margin-bottom: 20px;
}

.text-wrapper {
  max-width: 770px;
  text-align: center;
  line-height: 27px;
}

.text-bold {
  font-family: Roboto-Medium;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.footer {
  height: 50px;
  padding: 15px 0;
  text-align: center;
  font-size: 13px;
  color: #ccc;
  background-color: #f7f7f7;
  border-top: 1px solid #e7e7e7;
}

.footer .link {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
  text-decoration: none;
  color: #ababab;
}

.footer__years {
  padding-left: 5px;
  padding-right: 5px;
}

.help-block {
  color: #f00;
  font-size: 10px;
  position: absolute;
  top: 42px;
}

.has-error {
  position: relative;
}

.tabs {
  position: relative;
}

.spinner-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  display: none;
}

.spinner-block img {
  margin-top: 9%;
  width: 100px;
}

.select__collapse-items {
  max-height: 255px;
  overflow-y: auto;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert .close-btn {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  position: inherit;
  background-color: transparent;
}

.alert p {
  margin-bottom: 0;
}

.link_big {
  font-size: 1.3rem;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.contacts {
  font-size: 18px;
}

.contacts .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-item__photo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border: 5px solid #ececec;
}

@media (max-width: 767px) {
  .contact-item__photo {
    width: 102px;
    height: 102px;
  }
}

.contact-item__photo img {
  height: 100%;
  width: 100%;
}

.contact-item__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 124px;
  color: #404040;
  margin-left: 22px;
}

@media (max-width: 767px) {
  .contact-item__info {
    margin-left: 8px;
  }
}

.contact-item__name {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .contact-item__name {
    font-size: 21px;
  }
}

.contact-item__phone {
  font-size: 21px;
  font-family: Roboto-Medium;
  color: #404040;
  text-decoration: none;
  line-height: 1;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  .contact-item__phone {
    font-size: 18px;
  }
}

.contact-item__phone:hover {
  color: #404040;
  text-decoration: none;
}

.contact-item__position {
  font-size: 18px;
  color: #666;
  line-height: 1;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .contact-item__position {
    font-size: 16px;
  }
}

.contact-item__mail {
  font-size: 14px;
  color: #404040;
  line-height: 1;
}

.contact-item__mail:hover {
  color: #404040;
}

.contacts__items {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .contacts__items {
    flex-direction: column;
    align-items: center;
  }
  .contacts__items .contact-item ~ .contact-item {
    margin-top: 16px;
  }
}

@media (min-width: 992px) {
  .contacts__items::before {
    content: "";
    display: block;
    background-color: #999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 111px;
    opacity: .3;
  }
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.disclaimer {
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 12px;
  color: #ababab;
  text-align: justify;
}

.disclaimer::before {
  content: "";
  display: block;
  width: 70px;
  height: 1px;
  background-color: #dbdbdb;
  transform: translateY(-20px);
}

.disclaimer__title {
  font-size: 12px;
  font-family: Roboto-Bold;
}

.disclaimer__text {
  margin-bottom: 0;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.resources {
  padding-top: 50px;
  padding-bottom: 50px;
}

.resources h2 {
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .resources {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .resources h2 {
    margin-bottom: 20px;
  }
}

.resources__columns {
  display: flex;
}

@media (max-width: 992px) {
  .resources__columns {
    flex-direction: column;
    text-align: justify;
  }
}

.resources__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 992px) {
  .resources__column {
    width: 50%;
  }
  .resources__column:first-child {
    margin-right: 30px;
  }
}

.resources__item p {
  margin-top: 0;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.text-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-section--gray {
  background-color: #f7f7f7;
}

.text-section p {
  margin-bottom: 1rem;
  margin-top: .75rem;
}

@media (max-width: 992px) {
  .text-section {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: justify;
  }
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.tabs {
  margin-bottom: 80px;
}

.tabs__nav {
  display: flex;
  margin-bottom: -1px;
}

.tabs__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: 33px;
  height: 72px;
  text-transform: uppercase;
  font-family: Roboto-Medium;
  border-radius: 20px 20px 0 0;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-bottom-color: rgba(217, 217, 217, 0.7);
  color: #666;
  font-size: 18px;
}

@media (max-width: 992px) {
  .tabs__link {
    height: 54px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .tabs__link {
    padding: 20px;
  }
}

.tabs__link:focus {
  outline: 0;
}

.tabs__link:hover, .tabs__link:active {
  text-decoration: none;
  color: #f77f03;
  background-color: #fff;
  border-color: rgba(217, 217, 217, 0.7);
  border-bottom-color: #fff;
}

.tabs__link.is-active {
  background-color: #fff;
  color: #f77f03;
  border-color: rgba(217, 217, 217, 0.7);
  border-bottom-color: #fff;
  cursor: default;
}

.tabs__link ~ .tabs__link {
  margin-left: 20px;
}

.tabs__body {
  border: 1px solid rgba(217, 217, 217, 0.7);
}

.tabs__filters {
  display: flex;
  align-items: center;
  padding: 26px;
}

.tabs__filters--between {
  justify-content: space-between;
}

@media (max-width: 992px) {
  .tabs__filters {
    flex-direction: column;
    align-items: flex-start;
  }
  .tabs__filters .input-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.tabs__filters .select {
  flex: 1;
}

@media (min-width: 767px) {
  .tabs__filters .select {
    margin-right: 30px;
  }
}

.tabs__filters .checkbox-group__list .checkbox {
  margin-bottom: 0;
}

.tabs__filters ~ .tabs__filters {
  padding-top: 0;
}

.advanced-form {
  display: none;
  position: relative;
  padding: 26px;
  border-top: 1px solid rgba(217, 217, 217, 0.7);
}

.advanced-form.is-active {
  display: block;
}

.advanced-form::before {
  content: "";
  display: block;
  position: absolute;
  right: 50px;
  top: -8px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  background-color: #fff;
  border: 1px solid rgba(217, 217, 217, 0.7);
  border-right: 0;
  border-bottom: 0;
}

.tabs__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tabs__footer .btn-submit {
  display: block;
  margin-left: 25px;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .tabs__footer {
    flex-direction: column;
  }
  .tabs__footer .btn-submit {
    margin: 15px 0;
  }
}

.tabs__result {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabs__result:empty::before {
  content: "0 Results";
  font-family: Roboto-Regular;
  font-size: 18px;
  color: #404040;
}

.tabs__filters-checkboxes {
  display: flex;
  align-items: center;
}

.tabs__filters-checkboxes .checkbox:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 576px) {
  .tabs__filters-checkboxes {
    flex-direction: column;
    align-items: flex-start;
  }
  .tabs__filters-checkboxes .checkbox:not(:last-child) {
    margin-bottom: 8px;
  }
}

@media (max-width: 992px) {
  .second-tab {
    flex-direction: row;
  }
  .second-tab .select--fixed {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .second-tab {
    flex-direction: column;
    align-items: flex-start;
  }
  .second-tab .select.ml-30 {
    margin-left: 0;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .second-tab .input-group {
    align-items: flex-start;
    flex-direction: column;
  }
}

.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px 20px;
}

.search-result__links {
  margin-bottom: 30px;
}

.search-result__links .link {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.search-result__count, .search-result__value {
  display: flex;
  flex-direction: column;
}

.search-result__count {
  align-items: flex-end;
}

.search-result__value {
  align-items: flex-start;
}

.search-result__firms {
  /*display: grid;
  grid-template-columns: 1fr 1fr;*/
  font-family: Roboto-Bold;
  font-size: 18px;
  color: #404040;
}

.search-result__firms .res_count {
  display: flex;
  justify-content: flex-end;
}

.search-result__value {
  margin-right: 5px;
}

.search-result__executives {
  /*display: grid;
  grid-template-columns: 1fr 1fr;*/
  font-size: 14px;
  color: #999;
}

.search-result__executives .res_sub_count {
  display: flex;
  justify-content: flex-end;
}

.tab {
  display: none;
}

.tab.is-active {
  display: block;
}

.tab-title {
  display: flex;
  justify-content: flex-end;
  width: 166px;
  padding-right: 5px;
  margin-right: 15px;
  color: #404040;
  font-family: Roboto-Medium;
}

@media (max-width: 992px) {
  .tab-title {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 992px) {
  .input-group__title {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.hero {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.header {
  position: relative;
  top: 0;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(56, 56, 56, 0.1);
  z-index: 100;
}

.header.is-sticky {
  position: sticky;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;
}

.header__logo {
  margin-right: 100px;
}

.header__logo a {
  display: block;
}

.header__logo img {
  max-width: 100%;
}

.header__link {
  color: #666;
  font-size: 18px;
}

.header__link ~ .header__link {
  margin-left: 34px;
}

.header__link:hover {
  color: #f77f03;
  text-decoration: none;
}

.header__nav-btn {
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 14px;
  cursor: pointer;
}

.header__nav-btn .bar {
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #666;
  transition: all ease-in .2s;
}

.header__nav-btn .bar:nth-child(2) {
  position: absolute;
  top: 6px;
}

.header__nav-btn .bar:nth-child(3) {
  position: absolute;
  bottom: 0;
}

@media (min-width: 992px) {
  .header__nav-btn {
    display: none;
  }
}

.header__nav-btn.is-active .bar:nth-child(2) {
  opacity: 0;
}

.header__nav-btn.is-active .bar:first-child {
  transform: rotate(45deg);
  bottom: -6px;
}

.header__nav-btn.is-active .bar:last-child {
  transform: rotate(-45deg);
  top: 6px;
}

@media (max-width: 992px) {
  .header__nav {
    position: absolute;
    width: 100%;
    left: 0;
    top: 80px;
    overflow: hidden;
    display: flex;
    box-shadow: 0 1px 2px rgba(56, 56, 56, 0.1);
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    line-height: 2.77;
    border-top: 1px solid #eee;
    transform: translateY(-100%);
    transition: all .2s;
    z-index: -1;
  }
  .header__nav .header__link {
    margin: 0;
  }
  .header__nav .header__link:first-child {
    padding-top: 20px;
  }
  .header__nav .header__link:last-child {
    padding-bottom: 20px;
  }
  .header__nav.is-active {
    transform: translateY(0);
  }
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  display: none;
}

.modal.is-active {
  display: block;
}

.modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  position: relative;
  max-height: calc(100vh - 100px);
  margin: 50px auto;
  max-width: 880px;
  background-color: #fff;
  border: 1px solid #eee;
  overflow-y: auto;
  border-radius: 5px;
  z-index: 210;
}

.modal-dialog__header,
.modal-dialog__body {
  padding: 30px;
}

.modal-dialog__header {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.modal-dialog__header h2 {
  margin-bottom: 0;
  text-align: left;
  line-height: 1;
}

.modal-dialog__footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: 15px;
}

.modal-dialog__footer .btn {
  min-width: 120px;
}

.link {
  font-size: 14px;
  text-decoration: underline;
  color: #999;
  font-family: Roboto-Medium;
  letter-spacing: .14px;
}

.link:hover {
  opacity: .9;
  color: #999;
}

.link--xl {
  font-size: 18px;
}

.link--primary {
  color: #2ca2ed;
  text-decoration: none;
}

.link--primary:hover {
  color: #2ca2ed;
  text-decoration: none;
}

.link--accent {
  color: #f77f03;
  text-decoration: none;
}

.link--accent:hover {
  color: #f77f03;
}

.link--accent::selection {
  background-color: #2ca2ed;
  color: #fff;
}

.reset_filters {
  margin-right: 20px;
}

.reset_filters:hover {
  color: #f77f03;
}

.example_link {
  color: #f77f03;
  margin-right: 20px;
}

.example_link:hover {
  color: #f77f03;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.ml-30 {
  margin-left: 30px;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.btn-separate {
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid currentColor;
}

.btn {
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 15px;
  transition: all .5s ease-in-out;
  letter-spacing: .12px;
  border: 2px solid transparent;
  cursor: pointer;
}

.btn--default {
  background-color: #fff;
  border-color: #f77f03;
  color: #f77f03;
}

.btn--default:hover {
  color: #f77f03;
  text-decoration: none;
}

.btn--accent {
  background-color: #f77f03;
  border-color: #f77f03;
  color: #fff;
}

.btn--accent:hover {
  color: #f77f03;
  border-color: #f77f03;
  background-color: #fff;
  background-image: none;
  text-decoration: none;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0;
  box-shadow: none;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  opacity: .9;
}

.close-btn:hover {
  opacity: 1;
}

.close-btn:focus {
  outline: 0;
}

.close-btn span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #f77f03;
  line-height: 24px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .9;
}

@media all and (-ms-high-contrast: none) {
  .close-btn span {
    font-size: 12px;
  }
}

@supports (-ms-ime-align: auto) {
  .close-btn span {
    font-size: 12px;
  }
}

.close-btn span:hover {
  opacity: 1;
}

.link-underline {
  text-decoration: underline;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.checkbox-group__title {
  display: inline-block;
  font-family: Roboto-Medium;
  margin-bottom: 15px;
  color: #404040;
}

.checkbox__box {
  position: relative;
  display: inline-block;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-right: 15px;
  background-color: #fff;
}

.checkbox__box::after, .checkbox__box::before {
  content: "";
  position: absolute;
  display: none;
  background-color: #f77f03;
}

.checkbox__box::after {
  width: 12px;
  height: 2px;
  top: 8px;
  left: 5px;
  transform: rotate(-56deg);
}

.checkbox__box::before {
  width: 6px;
  height: 2px;
  top: 10px;
  left: 3px;
  transform: rotate(45deg);
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox input:checked ~ .checkbox__box::after, .checkbox input:checked ~ .checkbox__box::before {
  display: block;
}

.checkbox-group__list {
  display: flex;
}

@media (max-width: 767px) {
  .checkbox-group__list {
    flex-direction: column;
  }
}

.checkbox__label {
  font-size: 15px;
}

.checkbox-column-group {
  margin-right: 30px;
}

@media (max-width: 992px) {
  .checkbox-column-group {
    margin-right: 14px;
  }
}

@media (max-width: 767px) {
  .checkbox-column-group {
    margin-right: 0;
  }
}

.checkbox-column-group .checkbox {
  margin-bottom: 10px;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.radio__btn {
  position: relative;
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid #dedede;
  border-radius: 50%;
  margin-right: 10px;
}

.radio__btn::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.radio {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
}

.radio input:checked ~ .radio__btn::after {
  background-color: #f77f03;
}

.radio__title {
  margin-right: 14px;
  color: #404040;
  font-family: Roboto-Medium;
}

.radio-group {
  display: flex;
}

.radio-group .radio ~ .radio {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .radio-group {
    flex-direction: column;
  }
  .radio-group .checkbox {
    margin-bottom: 10px;
  }
  .radio-group .radio__title,
  .radio-group .radio {
    margin-bottom: 6px;
  }
  .radio-group .radio ~ .radio {
    margin-left: 0;
  }
}

.radio__label {
  font-size: 15px;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.switch__title {
  font-size: 15px;
  color: #404040;
  margin-right: 15px;
}

.switch__tumbler {
  position: relative;
  width: 48px;
  height: 23px;
  border-radius: 12px;
  border: 1px solid #dedede;
}

.switch__tumbler::after {
  content: "";
  position: absolute;
  top: -1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #bebebe;
  transition: transform linear .2s;
  transform: translateX(0);
}

.switch {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch input:checked ~ .switch__tumbler::after {
  transform: translateX(108%);
  background-color: #f77f03;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.form-group:not(:last-child) {
  margin-bottom: 35px;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group:not(:last-child) {
  margin-right: 50px;
}

@media (max-width: 992px) {
  .input-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .input-group .input__label {
    padding-left: 0;
    width: 40px;
  }
  .input-group .input:not(:first-of-type) {
    margin-top: 6px;
  }
}

.input__label {
  font-size: 15px;
  color: #404040;
  text-transform: lowercase;
  padding-right: 10px;
}

.input {
  display: flex;
  align-items: center;
}

.input ~ .input .input__label {
  padding-left: 10px;
}

.input-group__title {
  color: #404040;
  user-select: none;
  margin-right: 4px;
}

.input__field {
  min-width: 90px;
  padding: 0 15px;
  font-size: 14px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 17px;
  box-shadow: none;
}

.input__field:focus {
  outline: 0;
  border-color: #2ca2ed;
}

.input__field--xs {
  width: 90px;
}

.input__tag {
  color: #ababab;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.has-error input {
  border-color: rgba(255, 0, 0, 0.5);
  background: rgba(255, 0, 0, 0.025);
}

.help-block {
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
  padding: 12px;
  min-width: 210px;
}

.help-block:empty {
  display: none;
}

.help-block::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 25%;
  top: -6px;
  transform: rotate(45deg);
  border: 1px solid #dedede;
  border-bottom: 0;
  border-right: 0;
  z-index: 2;
}

@font-face {
  font-family: "Roboto-Light";
  src: resolve("Roboto-Light.woff") format("woff"), resolve("Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: resolve("Roboto-Medium.woff") format("woff"), resolve("Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: resolve("Roboto-Regular.woff") format("woff"), resolve("Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  src: resolve("Roboto-Bold.woff") format("woff"), resolve("Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: resolve("Roboto-Black.woff") format("woff"), resolve("Roboto-Black.woff2") format("woff2");
}

.select {
  position: relative;
}

.select--fixed {
  min-width: 245px;
  max-width: 245px;
}

.select__collapse {
  display: none;
  position: absolute;
  width: 100%;
  text-align: left;
  border: 1px solid #2ca2ed;
  border-top: 0;
  transform: translateY(-1px);
  background-color: #fff;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  z-index: 5;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 333px;
  border-radius: 0 0 17px 17px;
  padding-top: 10px;
}

.select__collapse::-webkit-scrollbar-track {
  display: none;
}

.select__collapse::-webkit-scrollbar {
  display: none;
}

.select__collapse::-webkit-scrollbar-thumb {
  display: none;
}

.select__button {
  position: relative;
  width: 100%;
  box-shadow: none;
  border: 1px solid #dedede;
  padding: 6px 24px 6px 12px;
  font-size: 15px;
  border-radius: 17px;
  background-color: #fff;
  color: #404040;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}

.select__button:focus {
  outline: 0;
}

.select__button::after {
  content: "";
  position: absolute;
  top: 14px;
  right: 10px;
  border-color: transparent;
  border-top-color: #888;
  border-style: solid;
  border-width: 5px 4px 0;
}

.select__button.is-active {
  border-radius: 17px 17px 0 0;
  border-color: #2ca2ed;
}

.select__button.is-active::after {
  transform: rotate(0.5turn);
}

.select__button.is-active ~ .select__collapse {
  display: block;
}

.select__item {
  display: block;
  padding: 6px 12px;
  color: #333;
  cursor: pointer;
}

.select__item.checkbox {
  display: flex;
}

.select__item .checkbox__label {
  color: #404040;
}

.select__item:hover, .select__item.is-active {
  text-decoration: none;
  color: #fff;
  background-color: #2ca2ed;
}

.select__item:hover .checkbox__label, .select__item.is-active .checkbox__label {
  color: #fff;
}

.select__submit {
  text-align: center;
  padding: 15px 20px 20px;
}

.select__submit .btn {
  height: 32px;
  width: 100%;
  padding: 5px 25px;
}

.box-section {
  padding-bottom: 0;
}

.box {
  background-color: #fff;
  border-radius: 20px;
  padding: 55px 55px 55px 40px;
  color: #404040;
}

.box__title h2 {
  font-size: 39px;
  text-align: left;
  color: #404040;
  line-height: 50px;
}

@media (max-width: 992px) {
  .box__title h2 {
    font-size: 30px;
    line-height: 32px;
  }
  .box__title h2 br {
    display: none;
  }
}

.box__body {
  display: flex;
}

.box__body .btn {
  display: block;
}

.box__body .box__info {
  max-width: 350px;
  width: 100%;
}

.box__body .box__info--full {
  max-width: 550px;
}

.box__body .box__info--full .btn {
  display: inline-block;
}

@media (max-width: 992px) {
  .box__body {
    flex-direction: column-reverse;
  }
}

.box__body .box__text {
  padding: 0;
  font-size: 21px;
  margin-bottom: 30px;
}

.box__text {
  padding-left: 70px;
  padding-right: 20px;
  color: #666;
  letter-spacing: 0.08px;
  font-weight: 300;
  font-size: 21px;
  text-align: left;
}

@media (max-width: 992px) {
  .box__text {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
}

.box__address {
  margin-bottom: 30px;
}

.box__address span,
.box__address .link {
  font-weight: 500;
  font-size: 18px;
  display: block;
}

.box__description {
  padding-top: 30px;
}

.box__footer {
  padding-top: 30px;
  color: #999;
}
