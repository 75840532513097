@import "../../styles/variables/variables";

.header {
  position: relative;
  top: 0;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(56, 56, 56, .1);
  z-index: 100;

  &.is-sticky {
    position: sticky;
  }
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;
}

.header__logo {
  margin-right: 100px;

  a {
    display: block;
  }

  img {
    max-width: 100%;
  }
}

.header__link {
  color: $c-font;
  font-size: 18px;

  ~ .header__link {
    margin-left: 34px;
  }

  &:hover {
    color: $c-accent;
    text-decoration: none;
  }
}

.header__nav-btn {
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 14px;
  cursor: pointer;

  .bar {
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    background-color: $c-font;
    transition: all ease-in .2s;

    &:nth-child(2) {
      position: absolute;
      top: 6px;
    }

    &:nth-child(3) {
      position: absolute;
      bottom: 0;
    }
  }

  @media (min-width: $md) {
    display: none;
  }

  &.is-active {
    .bar:nth-child(2) {
      opacity: 0;
    }

    .bar:first-child {
      transform: rotate(45deg);
      bottom: -6px;
    }

    .bar:last-child {
      transform: rotate(-45deg);
      top: 6px;
    }
  }
}

.header__nav {
  @media (max-width: $md) {
    position: absolute;
    width: 100%;
    left: 0;
    top: 80px;
    overflow: hidden;
    display: flex;
    box-shadow: 0 1px 2px rgba(56, 56, 56, .1);
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    line-height: 2.77;
    border-top: 1px solid #eee;
    transform: translateY(-100%);
    transition: all .2s;
    z-index: -1;

    .header__link {
      margin: 0;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }

    &.is-active {
      transform: translateY(0);
    }
  }
}
