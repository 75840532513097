@import "../../styles/variables/variables";

.text-section {
  padding-top: 50px;
  padding-bottom: 50px;

  &--gray {
    background-color: #f7f7f7;
  }

  p {
    margin-bottom: 1rem;
    margin-top: .75rem;
  }

  @media (max-width: $md) {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: justify;
  }
}
